<template>
    <div class="document-view">
        <div class="document-card">
            <div class="actions">
                <button
                    class="back-button at-btn crud__control-items__item at-btn--primary at-btn--primary at-btn--large"
                    @click="approveDocument"
                >
                    {{ $t('documents.view.buttons.approve') }}
                </button>
                <button
                    class="back-button at-btn crud__control-items__item at-btn--primary at-btn--primary at-btn--large"
                    @click="$router.go(-1)"
                >
                    {{ $t('documents.view.buttons.back') }}
                </button>
            </div>
            <h1>{{ $t('documents.view.title') }}</h1>
            <div v-if="document" class="details">
                <p>
                    <strong>{{ $t('documents.view.name') }}</strong> {{ document.name }}
                </p>
                <p>
                    <strong>{{ $t('documents.view.main') }}</strong> {{ document.company.attributes.name }}
                </p>
                <p>
                    <strong>{{ $t('documents.view.partner') }}</strong> {{ document.partner_company.attributes.name }}
                </p>
                <p>
                    <strong>{{ $t('documents.view.approved') }}</strong> {{ document.partner_company_approved }}
                </p>
                <p>
                    <strong>{{ $t('documents.view.version') }}</strong> {{ document.version }}
                </p>
            </div>
            <div class="file-preview" @click="openModal">
                <p>
                    <strong>{{ $t('documents.view.preview') }}</strong>
                </p>
                <component :is="fileViewer" v-if="fileViewer" :src="baseUrl + document.file_url" class="file-viewer" />
            </div>
        </div>

        <div v-if="isModalOpen" class="modal-overlay" @click="closeModal">
            <div class="modal-content" @click.stop>
                <component
                    :is="fileViewer"
                    v-if="fileViewer"
                    :src="baseUrl + document.file_url"
                    :style="{ transform: `scale(${zoomLevel})` }"
                    class="modal-file-viewer"
                />
                <button class="close-button" @click="closeModal">×</button>
            </div>
        </div>
    </div>
</template>

<script>
    import PdfViewer from '../../../document/components/PdfViewer.vue';
    import ImageViewer from '../../../document/components/ImageViewer.vue';
    import DocumentService from '../../../document/services/document.service';

    export default {
        name: 'CompanyDocumentView',
        components: {
            PdfViewer,
            ImageViewer,
        },
        data() {
            const baseUrl = process.env.VUE_APP_API_URL;

            return {
                document: null,
                service: new DocumentService(),
                baseUrl,
                isModalOpen: false,
                zoomLevel: 1,
            };
        },
        async created() {
            await this.fetchDocument();
        },
        computed: {
            fileExtension() {
                return this.document?.file_url?.split('.').pop().toLowerCase();
            },
            fileViewer() {
                if (this.fileExtension === 'pdf') return 'PdfViewer';
                if (['jpg', 'jpeg', 'png', 'heic'].includes(this.fileExtension)) return 'ImageViewer';
                return null;
            },
        },
        methods: {
            async fetchDocument() {
                try {
                    const { data } = await this.service.getItem(this.$route.params.id);
                    this.document = data.data.attributes;
                } catch (error) {
                    console.log('Error fetching document:', error);
                }
            },
            async approveDocument() {
                try {
                    const res = await this.service.update(
                        { document: { partner_company_approved: true } },
                        this.$route.params.id,
                    );

                    if (res.status == 200) {
                        this.$Notify({
                            type: 'success',
                            title: this.$t('notification.save.success.title'),
                            message: this.$t('notification.save.success.message'),
                        });

                        this.document = res.data.data.attributes;
                        this.$router.push({ name: 'company.documents.pending' });
                    }
                } catch (error) {
                    console.log('Error Updating document:', error);
                }
            },
            openModal() {
                this.isModalOpen = true;
                this.zoomLevel = 1;
            },
            closeModal() {
                this.isModalOpen = false;
            },
        },
    };
</script>

<style scoped lang="scss">
    .document-view {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
        font-family: Arial, sans-serif;
        background-color: #f5f7fa;
        margin-top: 10px;
        border-radius: 5px;

        .theme-dark & {
            background-color: #1e1e1e;
            color: #e0e0e0;
        }
    }

    .document-card {
        position: relative;
        background-color: #fff;
        border-radius: 8px;
        padding: 30px;
        max-width: 900px;
        width: 100%;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
        text-align: center;
        border: 1px solid #e0e0e0;

        .theme-dark & {
            background-color: #2c2c2c;
            border-color: #444;
            color: #f1f1f1;
        }
    }

    .file-preview {
        cursor: pointer;
        margin-top: 1.5rem;

        .theme-dark & {
            color: #b3b3b3;
        }
    }

    .actions {
        position: absolute;
        top: 15px;
        right: 15px;
        display: flex;
        gap: 10px;
    }

    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
    }

    .modal-content {
        position: relative;
        background-color: #fff;
        padding: 20px;
        border-radius: 8px;
        text-align: center;
        max-width: 90%;
        max-height: 90%;
        overflow: auto;

        .theme-dark & {
            background-color: #333;
            color: #e0e0e0;
        }
    }

    .modal-file-viewer {
        max-width: 100%;
        max-height: 100%;
        transition: transform 0.3s;
    }

    .close-button {
        position: absolute;
        top: 10px;
        right: 10px;
        background: transparent;
        border: none;
        font-size: 1.5rem;
        color: #333;
        cursor: pointer;
    }

    .close-button:hover {
        color: #ff0000;
    }

    .theme-dark .modal-content .close-button {
        color: #aaa;
    }

    .theme-dark .modal-content .close-button:hover {
        color: #ff6b6b;
    }
</style>
